import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {COMPANIES_ACTION_TYPES} = actionTypes;

export const insertCompany = (company) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: COMPANIES_ACTION_TYPES.COMPANIES_LOADING,
        })

        return axios.post(apiUrls.companies, company)
            .then(({data}) => {

                dispatch({
                    type: COMPANIES_ACTION_TYPES.COMPANIES_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: COMPANIES_ACTION_TYPES.COMPANIES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            })
    });
};

export const updateCompany = (id, company) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: COMPANIES_ACTION_TYPES.COMPANIES_LOADING,
        })

        return axios.put(`${apiUrls.companies}/${id}`, company)
            .then(({data}) => {

                dispatch({
                    type: COMPANIES_ACTION_TYPES.COMPANIES_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: COMPANIES_ACTION_TYPES.COMPANIES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteCompany = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: COMPANIES_ACTION_TYPES.COMPANIES_LOADING,
        })

        return axios.delete(`${apiUrls.companies}/${id}`)
            .then(({}) => {

                dispatch({
                    type: COMPANIES_ACTION_TYPES.COMPANIES_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: COMPANIES_ACTION_TYPES.COMPANIES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const getCompanies = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: COMPANIES_ACTION_TYPES.COMPANIES_LOADING,
        })

        return axios.get(apiUrls.companies)
            .then(({data}) => {

                dispatch({
                    type: COMPANIES_ACTION_TYPES.COMPANIES_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: COMPANIES_ACTION_TYPES.COMPANIES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpCompanies = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: COMPANIES_ACTION_TYPES.COMPANIES_CLEANUP,
        });
    });
};
