import axios from "axios";
import {apiUrls} from "../constants";
import {EXTERNAL_USER_ACTION_TYPES} from "../constants/actionTypes";

export const cleanUpActionItem = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_CLEANUP,
        });
    });
};

export const cleanUpError = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_ERROR_CLEANUP,
        });
    });
};

export const getActionItem = (nonsense) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_LOADING,
        });

        return axios.get(`${apiUrls.externalUsers}/${nonsense}`)
            .then(({data}) => {

                dispatch({
                    type: EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_GET_ASSOCIATED_ACTION_ITEM,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateActionItem = (companyId, cycleActionItem) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_LOADING,
        });

        return axios.put(`${apiUrls.externalUsers}/${companyId}`, cycleActionItem)
            .then(({data}) => {

                dispatch({
                    type: EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_UPDATE_ASSOCIATED_ACTION_ITEM,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: EXTERNAL_USER_ACTION_TYPES.EXTERNAL_USER_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};
