import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {ASSESSMENT_USERS_ACTION_TYPES} = actionTypes;

export const getAssessmentUsers = (companyId, assessmentId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ASSESSMENT_USERS_ACTION_TYPES.ASSESSMENT_USERS_LOADING,
        });

        return axios.get(`${apiUrls.assessmentUsers}/${companyId}/${assessmentId}`)
            .then(({data}) => {

                dispatch({
                    type: ASSESSMENT_USERS_ACTION_TYPES.ASSESSMENT_USERS_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: ASSESSMENT_USERS_ACTION_TYPES.ASSESSMENT_USERS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpCompaniesAssessmentsUsers = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: ASSESSMENT_USERS_ACTION_TYPES.ASSESSMENT_USERS_CLEANUP,
        });
    });
};