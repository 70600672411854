import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {SCALES_ACTION_TYPES} = actionTypes;

export const insertScale = (scale) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SCALES_ACTION_TYPES.SCALES_LOADING,
        })

        return axios.post(apiUrls.scales, scale)
            .then(({data}) => {

                dispatch({
                    type: SCALES_ACTION_TYPES.SCALES_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SCALES_ACTION_TYPES.SCALES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateScale = (id: number, scale) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SCALES_ACTION_TYPES.SCALES_LOADING,
        })

        return axios.put(`${apiUrls.scales}/${id}`, scale)
            .then(({data}) => {

                dispatch({
                    type: SCALES_ACTION_TYPES.SCALES_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SCALES_ACTION_TYPES.SCALES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteScale = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SCALES_ACTION_TYPES.SCALES_LOADING,
        })

        return axios.delete(`${apiUrls.scales}/${id}`)
            .then(({}) => {

                dispatch({
                    type: SCALES_ACTION_TYPES.SCALES_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: SCALES_ACTION_TYPES.SCALES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getScales = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SCALES_ACTION_TYPES.SCALES_LOADING,
        })

        return axios.get(apiUrls.scales)
            .then(({data}) => {

                dispatch({
                    type: SCALES_ACTION_TYPES.SCALES_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SCALES_ACTION_TYPES.SCALES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpScales = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: SCALES_ACTION_TYPES.SCALES_CLEANUP,
        });
    });
};