import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {USERS_ACTION_TYPES} = actionTypes;

export const insertUser = (user) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: USERS_ACTION_TYPES.USERS_LOADING,
        })

        return axios.post(apiUrls.users, user)
            .then(({data}) => {

                dispatch({
                    type: USERS_ACTION_TYPES.USERS_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: USERS_ACTION_TYPES.USERS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateUser = (id, user) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: USERS_ACTION_TYPES.USERS_LOADING,
        })

        return axios.put(`${apiUrls.users}/${id}`, user)
            .then(({data}) => {

                dispatch({
                    type: USERS_ACTION_TYPES.USERS_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: USERS_ACTION_TYPES.USERS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteUser = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: USERS_ACTION_TYPES.USERS_LOADING,
        })

        return axios.delete(`${apiUrls.users}/${id}`)
            .then(({}) => {

                dispatch({
                    type: USERS_ACTION_TYPES.USERS_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: USERS_ACTION_TYPES.USERS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const getUsers = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: USERS_ACTION_TYPES.USERS_LOADING,
        })

        return axios.get(apiUrls.users)
            .then(({data}) => {

                dispatch({
                    type: USERS_ACTION_TYPES.USERS_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: USERS_ACTION_TYPES.USERS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const sendInviteEmail = (id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: USERS_ACTION_TYPES.USERS_LOADING,
        });

        return axios.post(`${apiUrls.users}/send-invite-email/${id}`)
            .then(({data}) => {

                dispatch({
                    type: USERS_ACTION_TYPES.USERS_SEND_INVITE_EMAIL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: USERS_ACTION_TYPES.USERS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpUsers = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: USERS_ACTION_TYPES.USERS_CLEANUP,
        });
    });
};