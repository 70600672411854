import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {CYCLES_ACTION_TYPES} = actionTypes;

export const insertCycle = (cycle) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: CYCLES_ACTION_TYPES.CYCLES_LOADING,
        })

        return axios.post(apiUrls.cycles, cycle)
            .then(({data}) => {

                dispatch({
                    type: CYCLES_ACTION_TYPES.CYCLES_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: CYCLES_ACTION_TYPES.CYCLES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateCycle = (cycle) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: CYCLES_ACTION_TYPES.CYCLES_LOADING,
        })

        return axios.post(`${apiUrls.cycles}`, cycle)
            .then(({data}) => {

                dispatch({
                    type: CYCLES_ACTION_TYPES.CYCLES_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: CYCLES_ACTION_TYPES.CYCLES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
};

export const deleteCycle = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: CYCLES_ACTION_TYPES.CYCLES_LOADING,
        })

        return axios.delete(`${apiUrls.cycles}/${id}`)
            .then(({}) => {

                dispatch({
                    type: CYCLES_ACTION_TYPES.CYCLES_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: CYCLES_ACTION_TYPES.CYCLES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const getCycles = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: CYCLES_ACTION_TYPES.CYCLES_LOADING,
        })

        return axios.get(apiUrls.cycles)
            .then(({data}) => {

                dispatch({
                    type: CYCLES_ACTION_TYPES.CYCLES_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: CYCLES_ACTION_TYPES.CYCLES_ERROR,
                    payload: response?.data || "Unknown error",
                });
                ;

                reject(response?.data || "Unknown error");
            })
    });
};
