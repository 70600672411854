import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {ACTION_ITEMS_ACTION_TYPES} = actionTypes;

export const insertActionItem = (actionItem) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_LOADING,
        })

        return axios.post(apiUrls.actionItems, actionItem)
            .then(({data}) => {

                dispatch({
                    type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_INSERT,
                    payload: data,
                });

                resolve(actionItem);
            })
            .catch(({response}) => {

                dispatch({
                    type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
};

export const updateActionItem = (actionItem) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_LOADING,
        })

        return axios.post(`${apiUrls.actionItems}`, actionItem)
            .then(({data}) => {

                dispatch({
                    type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_UPDATE,
                    payload: data,
                });

                resolve(actionItem);
            })
            .catch(({response}) => {

                dispatch({
                    type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
};

export const deleteActionItem = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_LOADING,
        })

        return axios.delete(`${apiUrls.actionItems}/${id}`)
            .then(({}) => {

                dispatch({
                    type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
};

export const getActionItems = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_LOADING,
        })

        return axios.get(apiUrls.actionItems)
            .then(({data}) => {

                dispatch({
                    type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: ACTION_ITEMS_ACTION_TYPES.ACTION_ITEMS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
};
