import {SIDE_MENUBAR_ACTION_TYPES} from "../constants/actionTypes";

export const cleanUpSideBar = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: SIDE_MENUBAR_ACTION_TYPES.SIDE_BAR_CLEANUP,
        });
    });
};
