import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {SESSION_ACTION_TYPES} = actionTypes;


export const cleanUpSession = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_CLEANUP,
        });
    });
};

export const cleanUpError = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_ERROR_CLEANUP,
        });
    });
};


export const getRoles = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        })

        return axios.get(`${apiUrls.session}/roles`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ROLES,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getSidebarMenus = (userId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        })

        return axios.get(`${apiUrls.session}/sidebar-urls/${userId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SIDE_BAR_GET_MENUS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getCompanies = (userId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        })

        return axios.get(`${apiUrls.session}/companies/${userId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_COMPANIES,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getUsers = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/users/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_USERS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertUser = (companyId, user) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/users/${companyId}`, user)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_USER,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateUser = (companyId, user) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.put(`${apiUrls.session}/users/${companyId}/${user.id}`, user)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_USER,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteUser = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/users/${companyId}/${id}`)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_USER,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertMultipleUsers = (companyId, users) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/users/add-multiple/${companyId}`, users)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_INSERT_MULTIPLE_ASSOCIATED_USERS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const sendUserInviteEmail = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/users/send-invite-email/${companyId}/${id}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SEND_INVITE_EMAIL_ASSOCIATED_USER,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const lockUnlockUser = (companyId, id, locked) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/users/lock-unlock/${companyId}/${id}`, {
            locked
        })
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SEND_LOCK_UNLOCK_ASSOCIATED_USER,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getCompanyValueDrivers = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/company-value-drivers/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_COMPANY_VALUE_DRIVERS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertCompanyValueDriver = (companyId, companyValueDriver) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/company-value-drivers/${companyId}`, companyValueDriver)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_COMPANY_VALUE_DRIVER,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateCompanyValueDriver = (companyId, companyValueDriver) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.put(`${apiUrls.session}/company-value-drivers/${companyId}/${companyValueDriver.id}`, companyValueDriver)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_COMPANY_VALUE_DRIVER,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteCompanyValueDriver = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/company-value-drivers/${companyId}/${id}`)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_COMPANY_VALUE_DRIVER,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deactivateValueDriver = (companyId, companyValueDriver) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/company-value-drivers/deactivate-activate/${companyId}/${companyValueDriver.id}`, companyValueDriver)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_DEACTIVATE_ACTIVATE_ASSOCIATED_COMPANY_VALUE_DRIVER,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
}

export const getAssessments = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/assessments/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ASSESSMENTS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertAssessment = (companyId, assessment) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/assessments/${companyId}`, assessment)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_ASSESSMENT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateAssessment = (companyId, assessment) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.put(`${apiUrls.session}/assessments/${companyId}/${assessment.id}`, assessment)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ASSESSMENT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteAssessment = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/assessments/${companyId}/${id}`, {
            data: {
                isSoft: true
            }
        })
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_ASSESSMENT,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getUserScores = (companyId, assessmentId, userId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/scores/${companyId}/${assessmentId}/${userId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_USER_ASSESSMENT_SCORES,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getCompanyScores = (companyId, assessmentId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/scores/${companyId}/${assessmentId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_COMPANY_ASSESSMENT_SCORES,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getOverallsScores = (companyId, assessmentId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/scores-overalls/${companyId}/${assessmentId} `)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_USER_OVERALLS_SCORES,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getAssessmentUsers = (companyId, assessmentId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/assessment-users/${companyId}/${assessmentId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ASSESSMENT_USERS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getCompanyValueDriversScores = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/company-value-drivers-scores/${companyId}`)
            .then(({data}) => {
                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_COMPANY_DRIVERS_SCORES,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getAnswers = (companyId, assessmentId, userId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/answers/${companyId}/${assessmentId}/${userId}`)
            .then(({data}) => {
                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ANSWERS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const saveAnswer = (companyId, answer) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_ANSWER,
            payload: answer,
        });

        return axios.post(`${apiUrls.session}/answers/${companyId}`, answer)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_ANSWER_DONE,
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getUserAssessments = (companyId, userId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/user-assessment/${companyId}/${userId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_USER_ASSESSMENTS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const saveUserAssessment = (companyId, userAssessment) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/user-assessment/${companyId}`, userAssessment)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_USER_ASSESSMENT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getPRIOItems = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/prio-items/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_PRIO_ITEMS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const savePRIOItem = (companyId, prioItem) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_PRIO_ITEM,
            payload: prioItem,
        });

        return axios.post(`${apiUrls.session}/prio-items/${companyId}`, prioItem)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_PRIO_ITEM_DONE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getPRIOActions = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/prio-actions/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_PRIO_ACTIONS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertPRIOAction = (companyId, prioAction) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/prio-actions/${companyId}`, prioAction)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_PRIO_ACTION,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updatePRIOAction = (companyId, prioAction) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.put(`${apiUrls.session}/prio-actions/${companyId}/${prioAction.id}`, prioAction)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_PRIO_ACTION,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deletePRIOAction = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/prio-actions/${companyId}/${id}`, {
            data: {
                isSoft: true
            }
        })
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_PRIO_ACTION,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const changePRIOActionsOrder = (companyId, prioActions) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_PRIO_ACTION_CHANGE_ORDER,
            payload: prioActions,
        });

        return axios.patch(`${apiUrls.session}/prio-actions/change-order/${companyId}`, prioActions)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_PRIO_ACTION_CHANGE_ORDER_DONE,
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getStrategicInitiatives = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/strategic-initiatives/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_STRATEGIC_INITIATIVES,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertStrategicInitiative = (companyId, strategicInitiative) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/strategic-initiatives/${companyId}`, strategicInitiative)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_STRATEGIC_INITIATIVE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateStrategicInitiative = (companyId, strategicInitiative) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.put(`${apiUrls.session}/strategic-initiatives/${companyId}/${strategicInitiative.id}`, strategicInitiative)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_STRATEGIC_INITIATIVE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteStrategicInitiative = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/strategic-initiatives/${companyId}/${id}`, {
            data: {
                isSoft: true
            }
        })
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_STRATEGIC_INITIATIVE,
                    payload: {id},
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const saveStrategicInitiativesPRIOActions = (companyId, strategicInitiativeId, strategicInitiativesPRIOActions) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_STRATEGIC_INITIATIVE_PRIO_ACTION_CHANGE_ORDER,
            payload: {strategicInitiativeId, strategicInitiativesPRIOActions},
        });

        return axios.post(`${apiUrls.session}/strategic-initiatives-prio-actions/${companyId}/${strategicInitiativeId}`, strategicInitiativesPRIOActions)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_STRATEGIC_INITIATIVE_PRIO_ACTION_CHANGE_ORDER_DONE,
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getActionItems = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/action-items/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ACTION_ITEMS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertActionItem = (companyId, actionItem) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        })

        axios.post(`${apiUrls.session}/action-items/${companyId}`, actionItem)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_ACTION_ITEM,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateActionItem = (companyId, actionItem) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ACTION_ITEM,
            payload: actionItem,
        });

        return axios.put(`${apiUrls.session}/action-items/${companyId}/${actionItem.id}`, actionItem)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_DONE,
                    payload: data,
                });

                resolve(data)
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteActionItem = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/action-items/${companyId}/${id}`, {
            data: {
                isSoft: true
            }
        })
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_ACTION_ITEM,
                    payload: {id},
                })

                resolve(true)
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const changeActionItemsOrder = (companyId, actionItems) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_ORDER,
            payload: actionItems,
        });

        return axios.patch(`${apiUrls.session}/action-items/change-order/${companyId}`, actionItems)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_ORDER_DONE,
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const changeActionItemState = (companyId, id, actionItem, fromState, toState) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.patch(`${apiUrls.session}/action-items/change-state/${companyId}/${id}/${fromState}/${toState}`, actionItem)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_ACTION_ITEM_CHANGE_STATE,
                    payload: data,
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getCycles = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/cycles/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_CYCLES,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertCycle = (companyId, cycle) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        })

        return axios.post(`${apiUrls.session}/cycles/${companyId}`, cycle)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_CYCLE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateCycle = (companyId, cycle) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.put(`${apiUrls.session}/cycles/${companyId}/${cycle.id}`, cycle)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_CYCLE,
                    payload: data,
                })

                resolve(data)
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteCycle = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/cycles/${companyId}/${id}`, {
            data: {
                isSoft: true
            }
        })
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_CYCLE,
                    payload: {id},
                })

                resolve(id)
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const startCycle = (companyId, id, cycle) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.put(`${apiUrls.session}/cycles/start/${companyId}/${id}`, cycle)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_START_ASSOCIATED_CYCLE,
                    payload: data,
                })

                resolve(data)
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const stopCycle = (companyId, moveFrom, moveTo, cycle) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.put(`${apiUrls.session}/cycles/stop/${companyId}/${moveFrom}/${moveTo}`, cycle)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_STOP_ASSOCIATED_CYCLE,
                    payload: data,
                })

                resolve(data)
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const saveCyclesActionItems = (companyId, cycleId, cyclesActionItems) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_CYCLE_ACTION_ITEM_CHANGE_ORDER,
            payload: {cycleId, cyclesActionItems},
        });

        return axios.post(`${apiUrls.session}/cycles-action-items/${companyId}/${cycleId}`, cyclesActionItems)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_STRATEGIC_INITIATIVE_PRIO_ACTION_CHANGE_ORDER_DONE,
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertCycleActionItemsState = (companyId, cycleId, actionItemId, state) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/cycles/cycles-action-items-states/${companyId}/${cycleId}/${actionItemId}`, {state})
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
}

export const deleteCycleActionItemsState = (companyId, cycleId, actionItemId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/cycles/cycles-action-items-states/${companyId}/${cycleId}/${actionItemId}`)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_CYCLES_CYCLE_ACTION_ITEM_STATE_DELETE,
                    payload: {cycleId, actionItemId},
                });

                resolve({cycleId, actionItemId});
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
}

export const saveCyclesActionItemsStates = (companyId, cycleId, state, cyclesActionItemsStates) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_BOARD_CYCLE_ACTION_ITEM_STATE_CHANGE_ORDER,
            payload: {cycleId, state, cyclesActionItemsStates},
        });

        return axios.post(`${apiUrls.session}/board/cycles-action-items-states/${companyId}/${cycleId}/${state}`, cyclesActionItemsStates)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_BOARD_CYCLE_ACTION_ITEM_STATE_CHANGE_ORDER_DONE,
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const assignExternalUser = (companyId, externalUser) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.post(`${apiUrls.session}/assign-external-user/${companyId}`, externalUser)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ASSIGN_ASSOCIATED_EXTERNAL_USER,
                    payload: data,
                })

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
}

export const unAssignExternalUser = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/assign-external-user/${companyId}/${id}`)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UNASSIGN_ASSOCIATED_EXTERNAL_USER,
                    payload: {id},
                })

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
}


export const getReportsCycleActionItemsAudits = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/reports/cycle/action-items/audits/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_REPORTS_CYCLE_ACTIONITEMS_AUDITS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
}


export const getOpenTasks = (userId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/open-tasks/${userId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_OPEN_TASKS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
}


export const getExecHub = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/exechub/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_EXECHUB_SECTIONS_WIDGETS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getExecHubSections = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/exechub-sections/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_EXECHUB_SECTIONS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertExecHubSection = (companyId, execHubSection) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        })

        return axios.post(`${apiUrls.session}/exechub-sections/${companyId}`, execHubSection)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_EXECHUB_SECTION,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateExecHubSection = (companyId, execHubSection) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.put(`${apiUrls.session}/exechub-sections/${companyId}/${execHubSection.id}`, execHubSection)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION,
                    payload: data,
                })

                resolve(data)
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteExecHubSection = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/exechub-sections/${companyId}/${id}`)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_EXECHUB_SECTION,
                    payload: {id},
                })

                resolve(id)
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const changeExecHubSectionsOrder = (companyId, execHubSections) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION_CHANGE_ORDER,
            payload: execHubSections,
        });

        return axios.patch(`${apiUrls.session}/exechub-sections/change-order/${companyId}`, execHubSections)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_EXECHUB_SECTION_CHANGE_ORDER_DONE,
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getExecHubWidgets = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/exechub-widgets/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_EXECHUB_WIDGETS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const insertExecHubWidget = (companyId, execHubWidget) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        })

        return axios.post(`${apiUrls.session}/exechub-widgets/${companyId}`, execHubWidget)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_INSERT_ASSOCIATED_EXECHUB_WIDGET,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateExecHubWidget = (companyId, execHubWidget) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.put(`${apiUrls.session}/exechub-widgets/${companyId}/${execHubWidget.id}`, execHubWidget)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_UPDATE_ASSOCIATED_EXECHUB_WIDGET,
                    payload: data,
                })

                resolve(data)
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteExecHubWidget = (companyId, id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.delete(`${apiUrls.session}/exechub-widgets/${companyId}/${id}`)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_DELETE_ASSOCIATED_EXECHUB_WIDGET,
                    payload: {id},
                })

                resolve(id)
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getAssessmentsCompaniesDriversAnswersAverages = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/reports/assessments/company-value-drivers/answers/averages/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ASSESSMENTS_COMPANY_DRIVERS_SCORES_COMPARISONS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getAssessmentsCompanyValueDriversTypesAnswersAverages = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/reports/assessments/company-value-drivers/types/answers/averages/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ASSESSMENTS_OVERALL_SCORES_COMPARISONS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getValueDriversAcademies = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/value-drivers-academies`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_VALUE_DRIVERS_ACADEMIES,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getAssessmentsAnswers = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/reports/assessments/answers/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_ASSESSMENTS_COMPANY_DRIVERS_ANSWERS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getSettings = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/settings/${companyId}`)
            .then(({data}) => {
                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_SETTINGS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const saveSettings = (companyId, settings) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });


        return axios.post(`${apiUrls.session}/settings/${companyId}`, settings)
            .then(({}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_SETTINGS,
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getApiKey = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });

        return axios.get(`${apiUrls.session}/apiKey/${companyId}`)
            .then(({data}) => {
                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_GET_ASSOCIATED_APIKEY,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const saveApiKey = (companyId, apiKey) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        });


        return axios.post(`${apiUrls.session}/apiKey/${companyId}`, apiKey)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_SAVE_ASSOCIATED_APIKEY,
                    payload: data,
                });

                resolve(true);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};


export const getReportCovQPlusValueDrivers = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: SESSION_ACTION_TYPES.SESSION_LOADING,
        })

        return axios.get(`${apiUrls.session}/reports/covq-plus-value-drivers/${companyId}`)
            .then(({data}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_REPORT_GET_COVQ_PLUS_VALUE_DRIVERS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: SESSION_ACTION_TYPES.SESSION_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
}
