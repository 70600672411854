import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {PRIO_ITEMS_ACTION_TYPES} = actionTypes;

export const insertPRIOItem = (prioItem) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_LOADING,
        })

        return axios.post(apiUrls.prioItems, prioItem)
            .then(({data}) => {

                dispatch({
                    type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updatePRIOItem = (prioItem) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_LOADING,
        })

        return axios.post(`${apiUrls.prioItems}`, prioItem)
            .then(({data}) => {

                dispatch({
                    type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deletePRIOItem = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_LOADING,
        })

        return axios.delete(`${apiUrls.prioItems}/${id}`)
            .then(({}) => {

                dispatch({
                    type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getPRIOItems = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_LOADING,
        })

        return axios.get(apiUrls.prioItems)
            .then(({data}) => {

                dispatch({
                    type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: PRIO_ITEMS_ACTION_TYPES.PRIO_ITEMS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};
