import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {ASSESSMENTS_ACTION_TYPES} = actionTypes;

export const insertAssessment = (assessment) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_LOADING,
        })

      return  axios.post(apiUrls.assessments, assessment)
            .then(({data}) => {

                dispatch({
                    type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_INSERT,
                    payload: data,
                });

                resolve(assessment);
            })
            .catch(({response}) => {

                dispatch({
                    type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            })
    });
};

export const updateAssessment = (assessment) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_LOADING,
        })

        return   axios.post(apiUrls.assessments, assessment)
        .then(({data}) => {

            dispatch({
                type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_UPDATE,
                payload: data,
            });

            resolve(assessment);
        })
        .catch(({response}) => {

            dispatch({
                type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_ERROR,
                payload: response?.data || "Unknown error",
            });

            reject(response?.data || "Unknown error");
        });
    });
};

export const deleteAssessment = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_LOADING,
        })

        return  axios.delete(`${apiUrls.assessments}/${id}`)
        .then(({}) => {

            dispatch({
                type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_DELETE,
                payload: {id},
            });

            resolve(id);
        })
        .catch(({response}) => {

            dispatch({
                type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_ERROR,
                payload: response?.data || "Unknown error",
            });

            reject(response?.data || "Unknown error");
        });
    });
};

export const getAssessments = (companyId) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_LOADING,
        })

        return  axios.get(`${apiUrls.assessments}/${companyId}`)
        .then(({data}) => {

            dispatch({
                type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_GET_ALL,
                payload: data,
            });

            resolve(data);
        })
        .catch(({response}) => {

            dispatch({
                type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_ERROR,
                payload: response?.data || "Unknown error",
            });

            reject(response?.data || "Unknown error");
        });
    });
};

export const cleanUpAssessments = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: ASSESSMENTS_ACTION_TYPES.ASSESSMENTS_CLEANUP,
        });
    });
};