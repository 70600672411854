import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {ANSWERS_ACTION_TYPES} = actionTypes;

export const insertAnswer = (answer) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ANSWERS_ACTION_TYPES.ANSWERS_LOADING,
        })

        return axios.post(apiUrls.answers, answer)
            .then(({data}) => {

                dispatch({
                    type: ANSWERS_ACTION_TYPES.ANSWERS_INSERT,
                    payload: data,
                });

                resolve(answer);
            })
            .catch(({response}) => {

                dispatch({
                    type: ANSWERS_ACTION_TYPES.ANSWERS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateAnswer = (answer) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ANSWERS_ACTION_TYPES.ANSWERS_LOADING,
        })

        return axios.post(`${apiUrls.answers}`, answer)
            .then(({data}) => {

                dispatch({
                    type: ANSWERS_ACTION_TYPES.ANSWERS_UPDATE,
                    payload: data,
                });

                resolve(answer);
            })
            .catch(({response}) => {

                dispatch({
                    type: ANSWERS_ACTION_TYPES.ANSWERS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteAnswer = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ANSWERS_ACTION_TYPES.ANSWERS_LOADING,
        })

        return axios.delete(`${apiUrls.answers}/${id}`)
            .then(({}) => {

                dispatch({
                    type: ANSWERS_ACTION_TYPES.ANSWERS_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: ANSWERS_ACTION_TYPES.ANSWERS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const getAnswers = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ANSWERS_ACTION_TYPES.ANSWERS_LOADING,
        })

        return axios.get(apiUrls.answers)
            .then(({data}) => {

                dispatch({
                    type: ANSWERS_ACTION_TYPES.ANSWERS_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: ANSWERS_ACTION_TYPES.ANSWERS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};
