import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {COUNTRIES_ACTION_TYPES} = actionTypes;

export const insertCountry = (country) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: COUNTRIES_ACTION_TYPES.COUNTRIES_LOADING,
        })

        return axios.post(apiUrls.countries, country)
            .then(({data}) => {

                dispatch({
                    type: COUNTRIES_ACTION_TYPES.COUNTRIES_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: COUNTRIES_ACTION_TYPES.COUNTRIES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
};

export const updateCountry = (id: number, country) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: COUNTRIES_ACTION_TYPES.COUNTRIES_LOADING,
        })

        return axios.put(`${apiUrls.countries}/${id}`, country)
            .then(({data}) => {

                dispatch({
                    type: COUNTRIES_ACTION_TYPES.COUNTRIES_UPDATE,
                    payload: data,
                })

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: COUNTRIES_ACTION_TYPES.COUNTRIES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            })
    });
};

export const deleteCountry = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: COUNTRIES_ACTION_TYPES.COUNTRIES_LOADING,
        })

        return axios.delete(`${apiUrls.countries}/${id}`)
            .then(({}) => {

                dispatch({
                    type: COUNTRIES_ACTION_TYPES.COUNTRIES_DELETE,
                    payload: {id},
                })

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: COUNTRIES_ACTION_TYPES.COUNTRIES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
};

export const getCountries = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: COUNTRIES_ACTION_TYPES.COUNTRIES_LOADING,
        })

        return axios.get(apiUrls.countries)
            .then(({data}) => {

                dispatch({
                    type: COUNTRIES_ACTION_TYPES.COUNTRIES_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: COUNTRIES_ACTION_TYPES.COUNTRIES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
};

export const cleanUpCountries = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: COUNTRIES_ACTION_TYPES.COUNTRIES_CLEANUP,
        });
    });
};
