import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {PERMISSIONS_ACTION_TYPES} = actionTypes;

export const savePermissions = (permissions: []) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: PERMISSIONS_ACTION_TYPES.PERMISSIONS_LOADING,
        });

        return axios.post(`${apiUrls.permissions}`, permissions)
            .then(({data}) => {

                dispatch({
                    type: PERMISSIONS_ACTION_TYPES.PERMISSIONS_SAVE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: PERMISSIONS_ACTION_TYPES.PERMISSIONS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
};

export const getPermissions = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: PERMISSIONS_ACTION_TYPES.PERMISSIONS_LOADING,
        });

        return axios.get(apiUrls.permissions)
            .then(({data}) => {

                dispatch({
                    type: PERMISSIONS_ACTION_TYPES.PERMISSIONS_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: PERMISSIONS_ACTION_TYPES.PERMISSIONS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpPermissions = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: PERMISSIONS_ACTION_TYPES.PERMISSIONS_CLEANUP,
        });
    });
};