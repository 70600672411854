import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {ROLES_ACTION_TYPES} = actionTypes;

export const insertRole = (role) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ROLES_ACTION_TYPES.ROLES_LOADING,
        })

        return axios.post(apiUrls.roles, role)
            .then(({data}) => {

                dispatch({
                    type: ROLES_ACTION_TYPES.ROLES_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: ROLES_ACTION_TYPES.ROLES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateRole = (id: number, role) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ROLES_ACTION_TYPES.ROLES_LOADING,
        })

        return axios.put(`${apiUrls.roles}/${id}`, role)
            .then(({data}) => {

                dispatch({
                    type: ROLES_ACTION_TYPES.ROLES_UPDATE,
                    payload: role,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: ROLES_ACTION_TYPES.ROLES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteRole = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ROLES_ACTION_TYPES.ROLES_LOADING,
        })

        return axios.delete(`${apiUrls.roles}/${id}`)
            .then(({}) => {

                dispatch({
                    type: ROLES_ACTION_TYPES.ROLES_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: ROLES_ACTION_TYPES.ROLES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getRoles = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ROLES_ACTION_TYPES.ROLES_LOADING,
        })

        return axios.get(apiUrls.roles)
            .then(({data}) => {

                dispatch({
                    type: ROLES_ACTION_TYPES.ROLES_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: ROLES_ACTION_TYPES.ROLES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpRoles = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: ROLES_ACTION_TYPES.ROLES_CLEANUP,
        });
    });
};