import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {INDUSTRIES_ACTION_TYPES} = actionTypes;

export const insertIndustry = (industry) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_LOADING,
        })

        return axios.post(apiUrls.industries, industry)
            .then(({data}) => {

                dispatch({
                    type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateIndustry = (id: number, industry) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_LOADING,
        })

        return axios.put(`${apiUrls.industries}/${id}`, industry)
            .then(({data}) => {

                dispatch({
                    type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteIndustry = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_LOADING,
        })

        return axios.delete(`${apiUrls.industries}/${id}`)
            .then(({}) => {

                dispatch({
                    type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const getIndustries = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_LOADING,
        })

        return axios.get(apiUrls.industries)
            .then(({data}) => {

                dispatch({
                    type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpIndustries = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: INDUSTRIES_ACTION_TYPES.INDUSTRIES_CLEANUP,
        });
    });
};