import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {VALUE_DRIVERS_ACTION_TYPES} = actionTypes;

export const insertValueDriver = (valueDriver) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_LOADING,
        })

        return axios.post(apiUrls.valueDrivers, valueDriver)
            .then(({data}) => {

                dispatch({
                    type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateValueDriver = (id: number, valueDriver) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_LOADING,
        })

        return axios.put(`${apiUrls.valueDrivers}/${id}`, valueDriver)
            .then(({data}) => {

                dispatch({
                    type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteValueDriver = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_LOADING,
        })

        return axios.delete(`${apiUrls.valueDrivers}/${id}`)
            .then(({}) => {

                dispatch({
                    type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_DELETE,
                    payload: {id},
                })

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getValueDrivers = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_LOADING,
        })

        return axios.get(apiUrls.valueDrivers)
            .then(({data}) => {

                dispatch({
                    type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpValueDrivers = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: VALUE_DRIVERS_ACTION_TYPES.VALUE_DRIVERS_CLEANUP,
        });
    });
};