import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {ACADEMIES_ACTION_TYPES} = actionTypes;

export const insertAcademy = (academy) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ACADEMIES_ACTION_TYPES.ACADEMIES_LOADING,
        })

        return axios.post(apiUrls.academies, academy)
            .then(({data}) => {

                dispatch({
                    type: ACADEMIES_ACTION_TYPES.ACADEMIES_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: ACADEMIES_ACTION_TYPES.ACADEMIES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateAcademy = (id: number, academy) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ACADEMIES_ACTION_TYPES.ACADEMIES_LOADING,
        })

        return axios.put(`${apiUrls.academies}/${id}`, academy)
            .then(({data}) => {

                dispatch({
                    type: ACADEMIES_ACTION_TYPES.ACADEMIES_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: ACADEMIES_ACTION_TYPES.ACADEMIES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteAcademy = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ACADEMIES_ACTION_TYPES.ACADEMIES_LOADING,
        })

        return axios.delete(`${apiUrls.academies}/${id}`)
            .then(({}) => {

                dispatch({
                    type: ACADEMIES_ACTION_TYPES.ACADEMIES_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: ACADEMIES_ACTION_TYPES.ACADEMIES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const getAcademies = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: ACADEMIES_ACTION_TYPES.ACADEMIES_LOADING,
        })

        return axios.get(apiUrls.academies)
            .then(({data}) => {

                dispatch({
                    type: ACADEMIES_ACTION_TYPES.ACADEMIES_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: ACADEMIES_ACTION_TYPES.ACADEMIES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpAcademies = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: ACADEMIES_ACTION_TYPES.ACADEMIES_CLEANUP,
        });
    });
};