
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const LoadingIndicator = ({isOpen}) => {

    return (
        <Dialog open={isOpen} fullWidth={true} maxWidth={"sm"}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}>
            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center">
                    <CircularProgress/>
                </Box>
            </DialogContent>
        </Dialog>
    )
};

export default LoadingIndicator;
