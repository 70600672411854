import axios from "axios";
import {apiUrls, actionTypes} from "../constants";
import {AxiosAuthRefreshRequestConfig} from "axios-auth-refresh";

const {MEMBER_ACTION_TYPES} = actionTypes;

export const cleanUpMember = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: MEMBER_ACTION_TYPES.MEMBER_CLEANUP,
        });
    });
};

export const cleanUpError = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: MEMBER_ACTION_TYPES.MEMBER_ERROR_CLEANUP,
        });
    });
};

export const signIn = ({email, password}) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: MEMBER_ACTION_TYPES.MEMBER_LOADING,
        });

        return axios.post(`${apiUrls.authenticate}/signin`, {
            email,
            password
        }, {skipAuthRefresh: true} as AxiosAuthRefreshRequestConfig)
            .then(({data}) => {

                dispatch({
                    type: MEMBER_ACTION_TYPES.MEMBER_SIGN_IN,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: MEMBER_ACTION_TYPES.MEMBER_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    })
};

export const forgotPassword = ({email}) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: MEMBER_ACTION_TYPES.MEMBER_LOADING,
        });

        return axios.post(`${apiUrls.authenticate}/forgot-password`, {email})
            .then(({data}) => {

                dispatch({
                    type: MEMBER_ACTION_TYPES.MEMBER_FORGOT_PASSWORD,
                    payload: data,
                });

                resolve(data);
            })
            .catch((({response}) => {

                dispatch({
                    type: MEMBER_ACTION_TYPES.MEMBER_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            }));
    })
};

export const updateProfile = (profile) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: MEMBER_ACTION_TYPES.MEMBER_LOADING,
        });

        return axios.post(`${apiUrls.authenticate}/update-profile`, profile)
            .then(({data}) => {

                dispatch({
                    type: MEMBER_ACTION_TYPES.MEMBER_PROFILE_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: MEMBER_ACTION_TYPES.MEMBER_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
};

export const updateTour = (tour) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: MEMBER_ACTION_TYPES.MEMBER_LOADING,
        });

        return axios.post(`${apiUrls.authenticate}/update-tour`, tour)
            .then(({data}) => {

                dispatch({
                    type: MEMBER_ACTION_TYPES.MEMBER_TOUR_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: MEMBER_ACTION_TYPES.MEMBER_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    });
}

export const getPermissions = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: MEMBER_ACTION_TYPES.MEMBER_LOADING,
        });

        return axios.post(`${apiUrls.authenticate}/permissions`)
            .then(({data}) => {

                dispatch({
                    type: MEMBER_ACTION_TYPES.MEMBER_PERMISSIONS,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: MEMBER_ACTION_TYPES.MEMBER_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            })
    })
};
