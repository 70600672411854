import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {STAGES_ACTION_TYPES} = actionTypes;

export const insertStage = (stage) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: STAGES_ACTION_TYPES.STAGES_LOADING,
        })

        return axios.post(apiUrls.stages, stage)
            .then(({data}) => {

                dispatch({
                    type: STAGES_ACTION_TYPES.STAGES_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: STAGES_ACTION_TYPES.STAGES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const updateStage = (id: number, stage) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: STAGES_ACTION_TYPES.STAGES_LOADING,
        })

        return axios.put(`${apiUrls.stages}/${id}`, stage)
            .then(({data}) => {

                dispatch({
                    type: STAGES_ACTION_TYPES.STAGES_UPDATE,
                    payload: stage,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: STAGES_ACTION_TYPES.STAGES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const deleteStage = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: STAGES_ACTION_TYPES.STAGES_LOADING,
        })

        return axios.delete(`${apiUrls.stages}/${id}`)
            .then(({}) => {

                dispatch({
                    type: STAGES_ACTION_TYPES.STAGES_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: STAGES_ACTION_TYPES.STAGES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const getStages = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: STAGES_ACTION_TYPES.STAGES_LOADING,
        })

        return axios.get(apiUrls.stages)
            .then(({data}) => {

                dispatch({
                    type: STAGES_ACTION_TYPES.STAGES_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: STAGES_ACTION_TYPES.STAGES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpStages = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: STAGES_ACTION_TYPES.STAGES_CLEANUP,
        });
    });
};