import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {USER_COMPANIES_ROLES_ACTION_TYPES} = actionTypes;

export const getUserCompaniesRoles = (id) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_LOADING,
        });

        return axios.get(`${apiUrls.userCompaniesRoles}/${id}`)
            .then(({data}) => {

                dispatch({
                    type: USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_ERROR,
                    payload: response?.data || "Unknown error",
                })

                reject(response?.data || "Unknown error");
            });
    });
};

export const saveUserCompaniesRoles = (id: number, userCompaniesRoles: []) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_LOADING,
        });

        return axios.post(`${apiUrls.userCompaniesRoles}/${id}`, userCompaniesRoles)
            .then(({data}) => {

                dispatch({
                    type: USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_SAVE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const cleanUpUserCompaniesRoles = () => {

    return dispatch => new Promise(() => {

        dispatch({
            type: USER_COMPANIES_ROLES_ACTION_TYPES.USER_COMPANIES_ROLES_CLEANUP,
        });
    });
};