import axios from "axios";
import {apiUrls, actionTypes} from "../constants";

const {PRIO_ACTIONS_ACTION_TYPES} = actionTypes;

export const insertPRIOAction = (prioAction) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_LOADING,
        })

        return axios.post(apiUrls.prioActions, prioAction)
            .then(({data}) => {

                dispatch({
                    type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_INSERT,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const updatePRIOAction = (prioAction) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_LOADING,
        })

        return axios.post(`${apiUrls.prioActions}`, prioAction)
            .then(({data}) => {

                dispatch({
                    type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_UPDATE,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const deletePRIOAction = (id: number) => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_LOADING,
        })

        return axios.delete(`${apiUrls.prioActions}/${id}`)
            .then(({}) => {

                dispatch({
                    type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_DELETE,
                    payload: {id},
                });

                resolve(id);
            })
            .catch(({response}) => {

                dispatch({
                    type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};

export const getPRIOActions = () => {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({
            type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_LOADING,
        })

        return axios.get(apiUrls.prioActions)
            .then(({data}) => {

                dispatch({
                    type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_GET_ALL,
                    payload: data,
                });

                resolve(data);
            })
            .catch(({response}) => {

                dispatch({
                    type: PRIO_ACTIONS_ACTION_TYPES.PRIO_ACTIONS_ERROR,
                    payload: response?.data || "Unknown error",
                });

                reject(response?.data || "Unknown error");
            });
    });
};
